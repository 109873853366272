import Service from "./common/services/service";

class SessionTimer extends Service {
  timeoutInterval: number = 90000; // ms => 90 seconds
  secondsForWarning: number = 180; // s => 3 minutes
  secondsBeforeLogout: number = null;
  ajaxData: object;
  sentAlert = false;
  timer: any = null;
  timeout: any = null;
  lastSavedTime: any;

  constructor() {
    super();
    this.ajaxData = {
      authenticity_token: this.csrfToken()
    };
  }

  private checkTimeout = () => {
    try {
      if ((this.secondsBeforeLogout && this.lastSavedTime) && ((Date.now() - this.lastSavedTime) > (this.secondsBeforeLogout * 1000) + 1000)) {
        clearInterval(this.timer);
        goHome()
      } else {
        $.ajax({
          url: '/check_time_until_logout',
          type: 'GET',
          dataType: 'json',
          data: this.ajaxData,
          error: (jqXHR, exception) => {
            if (jqXHR.status == 401) {
              clearInterval(this.timer);
              goHome()
            }
          }
        }).then((d) => {
          if (d === null && (import.meta.env.MODE === 'test' || window.environment === 'test')) return;

          this.secondsBeforeLogout = d;
          this.lastSavedTime = Date.now();
          if (this.secondsBeforeLogout < this.secondsForWarning && !this.sentAlert) {
            clearInterval(this.timer);
            // @ts-ignore
            $('.session-timeout-modal').modal('show');
            this.timeout = setTimeout(goHome, d * 1000)
          }
        });
      }
    }
    catch (error) {
      console.error(error);
    }

  };

  public initTimer = () => {
    const timeoutModal: JQuery = $('.session-timeout-modal');
    if (timeoutModal.length) {
      this.secondsBeforeLogout = null;
      this.lastSavedTime = null;
      this.checkTimeout();
      this.timer = setInterval(this.checkTimeout, this.timeoutInterval);
      timeoutModal.off().on('hidden.bs.modal', () => {
        this.sentAlert = false;
      });
    }
  };

  public resetTimer = () => {
    if ((this.secondsBeforeLogout && this.lastSavedTime) && ((Date.now() - this.lastSavedTime) > (this.secondsBeforeLogout * 1000) + 1000)) {
      goHome()
    }
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.resetSession();
    this.secondsBeforeLogout = null;
    this.lastSavedTime = null;
    // this.checkTimeout();
    this.timer = setInterval(this.checkTimeout, this.timeoutInterval);
  };

  public resetSession = () => {
    $.ajax({
      url: '/reset_session_timeout',
      type: 'GET',
      dataType: 'json',
      data: this.ajaxData,
      error: (jqXHR, exception) => {
        if (jqXHR.status == 401) {
          goHome()
        }
      }
    }).done((d)=> {
      this.secondsBeforeLogout = d;
      this.lastSavedTime = Date.now();
    });
  };

  public resetAfterAjaxSuccess = (lastSavedTime, secondsBeforeLogout) => {
    this.lastSavedTime = lastSavedTime;
    this.secondsBeforeLogout = secondsBeforeLogout;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.timer = setInterval(this.checkTimeout, this.timeoutInterval);
  };


  public reset = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.initTimer();
    this.sentAlert = false;
  }
}

function goHome() {
  window.location.href = encodeURI(document.location.origin)
}

const sessionTimer = window.sessionTimer = new SessionTimer();

$(document).ajaxComplete((event, xhr) => {
  if (xhr.responseJSON && xhr.responseJSON.timeout) {
    sessionTimer.resetAfterAjaxSuccess(Date.now(), xhr.responseJSON.timeout);
  }
});

window.addEventListener("DOMContentLoaded", () =>{
  sessionTimer.reset();

  $('.session-timeout-modal').on('hide.bs.modal', () => {
    sessionTimer.resetTimer();
  });
});


